import React, { useContext } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { ThemeContext } from "styled-components"

import { hex2rgba } from "../../styles/tools"

export const CardRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  /* box-shadow: 0 0 0 1px red inset; */

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const CardWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 12rem;
  margin: 0 2rem 0rem;
  padding-top: 9rem;

  cursor: pointer;

  @media (max-width: 640px) {
    margin: 0 0 0.5rem;
  }
`

export const DummyCard = styled(CardWrapper)`
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;

  &[data-half-dummy="true"] {
    flex-basis: 4rem;
  }

  @media (max-width: 1024px) {
    flex-basis: 0;

    &[data-half-dummy="true"] {
      flex-basis: 0;
    }
  }
`

export const TypeTitle = styled.h2`
  display: block;
  margin-top: ${props => props.marginTop || 0};
  margin-bottom: 0;

  font-size: 1.8em;
  text-align: center;
`
export const TypeDescription = styled.p`
  display: block;
  margin-top: ${props => props.marginTop || 0};
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;

  font-style: italic;
  text-align: center;
  color: ${props => props.theme.color.primary};
`

const Content = styled.div`
  position: relative;
  padding: 10rem 0 2.125rem;

  background-color: ${props => props.theme.color.whiteBg};
  box-shadow: 0 2px 8px 4px ${props => hex2rgba(props.theme.color.primary, 0.1)};
  border-radius: 1.875rem;
`
const ImgAnchor = styled.div`
  position: relative;
`

const ImgWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-44%) scale(0.9);
  transform-origin: 40% 85%;
  transition: transform 0.3s ease-out;

  @media (max-width: 640px) {
    transform: translateX(-48%) scale(0.9);
  }

  display: flex;
  align-items: flex-end;

  &:hover {
    transform: translateX(-52%) scale(0.95) rotate(2deg);
  }

  &[data-active="true"] {
    transform: translateX(-42%) translateY(10%) scale(1) rotate(-10deg);
  }

  .gatsby-image-wrapper {
    img {
      display: block;
      margin-bottom: 0;
    }
  }
`

const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 0.8rem;

  color: ${props => props.theme.color.primary};
  font-family: ${props => props.theme.font.heading};
  font-size: 1.4em;
  text-align: center;

  background-color: ${props => props.theme.color.mediumBg};
  transition: all 0.2s ease-in-out;
`

export default function Card(props) {
  const themeContext = useContext(ThemeContext)

  const { data, activeFlavor, setActiveFlavor, flavorIndex, imageData } = props

  const isActive = activeFlavor === flavorIndex

  const labelStyles = {
    backgroundColor:
      isActive && data ? data.backgroundColor : themeContext.color.mediumBg,
    color: isActive && data ? data.textColor : themeContext.color.primary,
  }

  return (
    <CardWrapper
      onClick={e => {
        setActiveFlavor(activeFlavor === flavorIndex ? 0 : flavorIndex)
      }}
    >
      <Content>
        <ImgAnchor>
          <ImgWrapper data-active={isActive}>
            {imageData && <GatsbyImage image={imageData} alt="Flavor" />}
          </ImgWrapper>
        </ImgAnchor>
        <Label style={labelStyles}>{data ? data.name : ""}</Label>
      </Content>
    </CardWrapper>
  )
}
